body {
  overflow-x: hidden;
}

.height-auto {
  height: auto;
}

.award-content {
  h2 {
    max-width: 20em;
    font-size: 3.8em;
    line-height: 1.29;
  }
  p {
    max-width: 34.28em;
    margin-top: 2.35em;
    margin-bottom: 3.35em;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 1.4em;
    line-height: 1.57;
    font-weight: 400;
    letter-spacing: 0.05em;
  }
}

.author-avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 4px solid #fff;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
}

.single-blog-post-wrapper, .single-info-post-wrapper {
  p {
    font-size: 1.4em;
    line-height: 1.57;
    font-weight: 500;
    letter-spacing: 0.02em;
  }
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-40 {
  margin-top: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}

.info-center-sidebar-title {
  color: #1a2954;
  text-decoration: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 23px;
  height: 23px;
  cursor: pointer;
  background: #1a2954;
  border-radius: 50%;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #e7eaf100;
  border-radius: 4px;
}
input[type=range] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
  border-radius: 4px;
}
input[type=range]:focus {
  outline: none;
  border-radius: 4px;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: #e7eaf100;
  border-radius: 4px;
  border: 0px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  border: 0px solid #000000;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  background: #1a2954;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #e7eaf100;
  border-radius: 4px;
}

.logos {
  max-width: 100%;
  max-height: 100%;
}

.text-left {
  text-align: left !important;
}

body.search {
  padding: 0;
}

.search-result-box {
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}