/*
Theme Name: Alotech
Theme URI: https://alotech.com.tr/
Author:
Author URI:
Description:
Version: 1.0.0
License: GNU General Public License
License URI: https://www.gnu.org/licenses/gpl.html
Tags: boilerplate
Text Domain: alotech
*/

// Custom bootstrap variable overrides
@import "styles/custom";
